import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  buildNotification,
  ErrorFallback,
  GenerateNotification,
  Loader,
} from 'smart-react';
import SmartGallery from '../Components/SmartGallery';
import UploadImages from '../Components/UploadImages';
import MultiProcessedData from '../Components/MultiProcessedData';
import './AiModels.scss';
import { listImages, processImage } from '../Services/imageService';
import {
  CREATE_MESSAGE,
  ERROR_MESSAGE,
  ROAD_DEFECTS,
} from '../../../constants/applicationConstants';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';

/**
 * Component for managing road defects images.
 * @returns {JSX.Element} RoadDefects component.
 */
const RoadDefects = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [processedData, setProcessedData] = useState(null);
  const [images, setImages] = useState({
    approved: [],
    rejected: [],
    raw: [],
  });
  const [isUploadVisible, setIsUploadVisible] = useState(false);
  /**
   * Fetches the photos from the server.
   */
  const fetchPhotos = useCallback(async () => {
    setIsLoader(true);
    try {
      const response = await listImages(ROAD_DEFECTS);
      const { payload, isSuccess } = response;
      if (isSuccess) {
        const approved = payload.filter((image) => image.status === 'approved');
        const rejected = payload.filter((image) => image.status === 'rejected');
        const raw = payload.filter((image) => !image.status);
        setImages({ approved, rejected, raw });
        setIsUploadVisible(false);
      } else {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: 'Error fetching data:' + response.error,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
        setIsLoader(false);
      }
    } catch (error) {
      handleFetchError(error.message);
    } finally {
      setIsLoader(false);
    }
  }, []);
  /**
   * Handles the fetch error.
   * @param {Error} error - The error object.
   */
  const handleFetchError = (error) => {
    GenerateNotification(
      buildNotification({
        title: ERROR_MESSAGE?.title,
        description: error,
        style: ERROR_MESSAGE?.style,
      }),
      NOTIFICATION_TYPES.APP,
      EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
    );
  };
  /**
   * Processes the selected images.
   * @param {Array} images - The images to be processed.
   */
  const handleProcessImage = useCallback(async (images) => {
    setIsLoader(true);
    try {
      const response = await processImage({ images }, ROAD_DEFECTS);
      const { payload, isSuccess } = response;
      if (isSuccess) {
        setProcessedData(payload);
        GenerateNotification(
          buildNotification({
            title: 'Image Processed Successfully!',
            style: CREATE_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: 'Error processing image:' + response.error,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      handleProcessError(error);
    } finally {
      setIsLoader(false);
    }
  }, []);
  /**
   * Handles the process error.
   * @param {Error} error - The error object.
   */
  const handleProcessError = (error) => {
    GenerateNotification(
      buildNotification({
        title: ERROR_MESSAGE?.title,
        description: error.message,
        style: ERROR_MESSAGE?.style,
      }),
      NOTIFICATION_TYPES.APP,
      EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
    );
  };

  useEffect(() => {
    fetchPhotos();
  }, []);

  const filteredImages = useMemo(() => {
    const { approved, rejected, raw } = images;
    return {
      raw,
      approved,
      rejected,
    };
  }, [images]);

  const handleBackClick = useCallback(() => {
    setProcessedData(null);
    fetchPhotos();
  }, [fetchPhotos]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className={`k-mt-5 ${!isUploadVisible?'heading-border':""}`}>
        {isLoader && (
          <div className="loader-wrapper">
            <Loader />
          </div>
        )}
        {((!isUploadVisible && processedData) ||
          (processedData && processedData.isSuccess === 'true')) && (
          <button
            onClick={handleBackClick}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-dark k-float-right k-mr-5"
          >
            Back
          </button>
        )}
        {!isUploadVisible && !processedData && (
          <button
            onClick={() => setIsUploadVisible(true)}
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-dark k-float-right k-mr-6 "
          >
            Upload Images
          </button>
        )}
        {!isUploadVisible && !processedData && (
          <h6 className="k-pl-5 k-pt-2 k-b-0">
            The Road Defects Model Images List
          </h6>
        )}
      </div>
      {!isUploadVisible && !processedData && (
        <div className="smart-galleries k-p-4">
          <>
            {Object.entries(filteredImages).map(([key, photos]) => (
              <SmartGallery
                key={key}
                photos={photos}
                title={
                  key === 'approved'
                    ? 'Approved Images'
                    : key === 'rejected'
                      ? 'Rejected Images'
                      : 'Images To Be Processed'
                }
                onProcessImage={handleProcessImage}
                imageClick={true}
                select={
                  key === 'approved' ? false : key === 'rejected' ? false : true
                }
                type="raw"
              />
            ))}
          </>
        </div>
      )}
      {isUploadVisible && (
        <UploadImages
          onComplete={fetchPhotos}
          type={ROAD_DEFECTS}
          title={'Defected Roads'}
        />
      )}
      {!isUploadVisible && processedData && (
        <MultiProcessedData
          imagesData={processedData}
          updatePhotos={fetchPhotos}
          type={ROAD_DEFECTS}
          setProcessedData={setProcessedData}
        />
      )}
    </ErrorBoundary>
  );
};

export default RoadDefects;
