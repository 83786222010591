import React, { useState, useCallback } from 'react';
import { buildNotification, GenerateNotification, Loader } from 'smart-react';
import { uploadImagesData } from '../Services/imageService';
import { useDropzone } from 'react-dropzone';
import {
  CREATE_MESSAGE,
  ERROR_MESSAGE,
} from '../../../constants/applicationConstants';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';
import './UploadImages.scss';
/**
 * Component for uploading images.
 * @param {Function} onComplete - Callback function triggered when upload is complete.
 * @param {string} type - Type of the upload.
 * @param {string} title - Title for the upload section.
 * @returns {JSX.Element} UploadImages component.
 */
const UploadImages = ({ onComplete, type, title = '' }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  /**
   * Callback function invoked when files are dropped.
   * @param {Array} acceptedFiles - Array of accepted files.
   */
  const onDrop = useCallback((acceptedFiles) => {
    const imageFiles = acceptedFiles.filter((file) =>
      file.type.startsWith('image/')
    );
    const nonImageFiles = acceptedFiles.filter(
      (file) => !file.type.startsWith('image/')
    );

    if (nonImageFiles.length > 0) {
      GenerateNotification(
        buildNotification({
          title: ERROR_MESSAGE?.title,
          description: 'Only image files are allowed.',
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...imageFiles]);
  }, []);

  /**
   * Handles the process of uploading images.
   */
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*', // Accept only image files
  });
  const handleProcess = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => formData.append('files', file));
    setIsLoader(true);
    try {
      const response = await uploadImagesData(formData, type);
      if (response.isSuccess) {
        setSelectedFiles([]);
        if (onComplete) {
          onComplete();
        }
        GenerateNotification(
          buildNotification({
            title: 'Successfully Uploaded!',
            style: CREATE_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: ERROR_MESSAGE?.title,
          description: 'Error uploading files: ' + error.message,
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setIsLoader(false);
    }
  };
  /**
   * Removes a file from the selected files.
   * @param {number} index - Index of the file to be removed.
   */
  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="container-div k-pr-4 k-pl-4">
      {isLoader && (
        <div className="loader-wrapper">
          <Loader />
        </div>
      )}
      <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-dark k-mt-2 k-mr-5 k-float-right"
        onClick={onComplete}
      >
        Back
      </button>
      <h6 className="k-p-4 ">Upload {title} Images</h6>
      <div className="k-p-5 dropzone k-mr-5 k-ml-5" {...getRootProps()}>
        <input {...getInputProps()} />
        <p className="k-text-center">
          Drag 'n' drop some files here, or click to select files
        </p>
      </div>
      <div
        className="uploaded-files k-p-5 k-pt-0"
        style={{ display: 'flex', flexWrap: 'wrap' }}
      >
        {selectedFiles.map((file, index) => (
          <div key={index} className="custom-card">
            <div className="card-body">
              <span className="file-name">{file.name}</span>
              <button
                className="delete-button k-button-icon k-font-icon k-i-trash"
                onClick={() => removeFile(index)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="k-p-4 k-text-right">
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-mt-2"
          onClick={handleProcess}
          disabled={isLoader || selectedFiles.length === 0}
        >
          {isLoader ? 'Uploading...' : 'Upload'}
        </button>
      </div>
    </div>
  );
};

export default UploadImages;
