import React, { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Card, CardHeader, CardBody } from '@progress/kendo-react-layout';
import { Switch } from '@progress/kendo-react-inputs';
import './SmartGallery.scss';

/**
 * Component for displaying a gallery of photos with various functionalities.
 * @param {object} props - Component props.
 * @param {Array} props.photos - Array of photo objects.
 * @param {string} props.title - Title for the gallery.
 * @param {Function} props.onProcessImage - Function to process selected images.
 * @param {boolean} props.imageClick - Boolean indicating whether clicking on images is enabled.
 * @param {string} props.type - Type of gallery.
 * @param {boolean} [props.select=false] - Boolean indicating whether selection is enabled.
 * @returns {JSX.Element} Rendered SmartGallery component.
 */
const SmartGallery = ({
  photos,
  title,
  onProcessImage,
  imageClick,
  type,
  select = false,
}) => {
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (select) {
      setSelectedPhotos(photos);
    }
  }, [select, photos]);

  useEffect(() => {
    // Check if all photos are selected to set the selectAll state
    if (selectedPhotos.length === photos.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectedPhotos, photos]);
  /**
   * Opens the dialog with the selected photo details.
   * @param {Object} photo - Selected photo object.
   */
  const openDialog = (photo) => {
    setSelectedPhoto(photo);
  };
  /**
   * Closes the dialog.
   */
  const closeDialog = () => {
    setSelectedPhoto(null);
  };
  /**
   * Toggles the selection of a photo.
   * @param {Object} photo - Selected photo object.
   */
  const toggleSelection = (photo) => {
    if (select) {
      if (!selectedPhotos.find((selected) => selected.id === photo.id)) {
        setSelectedPhotos([...selectedPhotos, photo]);
      } else {
        setSelectedPhotos(
          selectedPhotos.filter((selected) => selected.id !== photo.id)
        );
      }
    } else {
      openDialog(photo);
    }
  };
  /**
   * Processes the selected images.
   */
  const processImage = () => {
    if (selectedPhotos.length > 0) {
      onProcessImage(selectedPhotos);
    }
  };
  /**
   * Handles the select all toggle.
   */
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedPhotos(photos);
    } else {
      setSelectedPhotos([]);
    }
  };

  return (
    <div className="smart-gallery">
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center flex-container k-pb-3 k-pt-3">
            <p className="m-0">{title}</p>
            {select && selectedPhotos.length > 0 && (
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={processImage}
                disabled={selectedPhotos.length === 0}
              >
                <span className="k-button-icon k-font-icon k-i-arrow-rotate-cw" />
                Process
              </button>
            )}
          </div>
        </CardHeader>
        <CardBody>
          {select && photos.length > 0 && (
            <div className="k-pl-2 k-mb-1-">
              <span className="m-0 k-font-size-xs">
                Toggle to Select/Unselect All
              </span>
              <p>
                <Switch checked={selectAll} onChange={handleSelectAll} />
              </p>
            </div>
          )}
          <div className="gallery-container">
            {photos.length === 0 ? (
              <div>No data found</div>
            ) : (
              photos.map((photo) => (
                <div key={photo.id} className="gallery-image-wrapper">
                  <img
                    onClick={() => toggleSelection(photo)}
                    src={
                      photo?.status === 'approved' ||
                      photo?.status === 'rejected'
                        ? photo.result_file_path
                        : photo.file_path
                    }
                    className={
                      selectedPhotos.find(
                        (selected) => selected.id === photo.id
                      )
                        ? 'selected gallery-image'
                        : 'gallery-image'
                    }
                    alt={`Photo ${photo.id}`}
                  />
                </div>
              ))
            )}
          </div>
        </CardBody>
      </Card>
      {selectedPhoto && (
        <Dialog title={'Photo Details'} onClose={closeDialog}>
          {selectedPhoto.status === 'rejected' ||
          selectedPhoto.status === 'approved' ? (
            <div style={{ textAlign: 'center' }}>
              <img
                src={selectedPhoto.result_file_path}
                alt={`Selected Photo`}
              />
              {selectedPhoto?.comment &&
                selectedPhoto.comment !== undefined && (
                  <>
                    <h6 className="k-text-left">Comment</h6>
                    <p className="k-text-left k-ml-4">
                      {selectedPhoto.comment}
                    </p>
                  </>
                )}
              <h6 style={{ textAlign: 'left' }}>Tag Counts</h6>
              <ul style={{ textAlign: 'left' }}>
                {Object.entries(selectedPhoto.tag_counts).map(
                  ([key, value]) => (
                    <li key={key}>
                      {key}: {value}
                    </li>
                  )
                )}
              </ul>
            </div>
          ) : (
            <div className="k-text-center">
              <img src={selectedPhoto.file_path} alt={`Selected Photo`} />
              <p className="k-text-center k-m-25">
                Are you sure you want to continue?
              </p>
            </div>
          )}
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={closeDialog}
            >
              Close
            </button>
            {selectedPhoto.status === undefined && (
              <button
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={processImage}
              >
                Process
              </button>
            )}
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default SmartGallery;
