import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import getRoutes from './Routes/Routes';
import './assets/scss/style.scss';
import './assets/scss/common/Responsive.scss';
import {AuthProvider} from './Modules/Core/Context/AuthContext';
import {NotificationProvider} from './Modules/Core/Context/NotificationContext'; // Import SmartNotification and NotificationProvider
import SmartNotification from './Modules/Core/Components/Notification/SmartNotification';
import Favicon from "react-favicon";
import icon from "./assets/icons/favicon.png"
import AuthGaurd from './Modules/Core/Components/AuthGaurd/AuthGaurd';
import {Loader, ErrorFallback, GlobalProvider, AppNotifications} from 'smart-react';
const MetaTags = React.lazy(
    () => import("./Modules/Core/Components/MetaTags/Tags")
);
const DrawerRouterContainer = React.lazy(() =>
    import(
        /* webpackPreload: true */ './Modules/Core/Components/Drawer/DrawerRouterContainer'
        )
);


function App() {

    return (
        <React.Suspense fallback={<Loader/>}>
            <Favicon url={icon}/>
            <React.Suspense>
                <MetaTags/>
            </React.Suspense>
            <GlobalProvider>
                <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onReset={() => {
                        // reset the state of your app so the error doesn't happen again
                    }}
                >
                    <React.Suspense>
                        <AppNotifications/>
                    </React.Suspense>
                </ErrorBoundary>
                <AuthProvider>
                    <NotificationProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<AuthGaurd/>}>
                                    <Route path="/" element={<DrawerRouterContainer/>}>
                                        {getRoutes('CardLayout')}
                                        {getRoutes('TileLayout')}
                                    </Route>
                                    {getRoutes('AuthEmptyLayout')}
                                </Route>
                                {getRoutes('EmptyLayout')}
                            </Routes>
                        </BrowserRouter>
                        <SmartNotification/>
                    </NotificationProvider>
                </AuthProvider>
            </GlobalProvider>
        </React.Suspense>
    );
}

export default App;
