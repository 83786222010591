import React, { useState } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

function ActivityDetection() {
  const [selectedTab, setSelectedTab] = useState(0); // Set the default selected tab index

  const handleSelect = (e) => {
    setSelectedTab(e.selected);
  };

  return (
    <>
      <div className="k-mt-5 heading-border">
        <h6 className="k-pl-5 k-pt-2 k-b-0 k-mb-2">
          Activity Detection (Single & Multiple Angle Camera)
        </h6>
      </div>
      <div className="multi-image-upload k-mt-5">
        <TabStrip selected={selectedTab} onSelect={handleSelect}>
          <TabStripTab title="Single Angle" contentClassName="k-text-center">
            <div className="video-container">
              <video
                width="800"
                height="450"
                controls
              >
                <source
                  src="https://smartisdev.blob.core.windows.net/ois-ai-models-app/AiVideos/Single%20angle%20-%20Table%20Tennis%20Area.mp4?sp=r&st=2024-04-02T14:20:14Z&se=2025-04-02T22:20:14Z&spr=https&sv=2022-11-02&sr=b&sig=xfs605ezYEVGJV%2BFHlkMHj9gAp5ojz2X6d1g2egQGh8%3D"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </TabStripTab>
          <TabStripTab
            title="Multiple Angle"
            contentClassName={'k-text-center'}
          >
            <div className="video-container">
              <video
                width="800"
                height="450"
                controls
              >
                <source
                  src="https://smartisdev.blob.core.windows.net/ois-ai-models-app/AiVideos/Multiple%20angles%20-%20Hall%20Area.mp4?sp=r&st=2024-04-02T14:24:51Z&se=2025-04-02T22:24:51Z&spr=https&sv=2022-11-02&sr=b&sig=K40%2FEElUoqsCqJLojZJ5MAvOv6suitxZIz1cXX4mKKQ%3D"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </TabStripTab>
        </TabStrip>
      </div>
    </>
  );
}

export default ActivityDetection;
