import React, { useEffect, useRef, useState } from 'react';
import * as faceapi from 'face-api.js';
import { Loader } from 'smart-react';
import { Button } from '@progress/kendo-react-buttons';
import './FaceRecognition.scss';

function FaceRecognition() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isWebcamStarted, setIsWebcamStarted] = useState(false);
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const loadModelsAndStart = async () => {
      await Promise.all([
        faceapi.nets.ssdMobilenetv1.loadFromUri('/models'),
        faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
        faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
      ]);
    };

    const getLabeledFaceDescriptions = async () => {
      const labels = [
        'Haris Ahmad',
        'Qamar Abbas',
        'Javad Ahmad',
        'Mahdi Khan',
      ];
      const loaderPromises = labels.map(async (label) => {
        const descriptions = [];
        for (let i = 1; i <= 40; i++) {
          setIsLoading(true); // Set loading to true before processing images
          const img = await faceapi.fetchImage(`./labels/${label}/${i}.png`);
          const detections = await faceapi
            .detectAllFaces(img)
            .withFaceLandmarks()
            .withFaceDescriptors();

          detections.forEach((detection) => {
            descriptions.push(detection.descriptor);
          });
          setIsLoading(false);
        }

        return new faceapi.LabeledFaceDescriptors(label, descriptions);
      });

      return Promise.all(loaderPromises);
    };

    const onVideoPlay = async () => {
      const labeledFaceDescriptors = await getLabeledFaceDescriptions();
      const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors);

      const canvas = canvasRef.current;
      const video = videoRef.current;

      if (canvas && video) {
        const displaySize = {
          width: videoDimensions.width,
          height: videoDimensions.height,
        };
        faceapi.matchDimensions(canvas, displaySize);

        setInterval(async () => {
          const detections = await faceapi
            .detectAllFaces(video)
            .withFaceLandmarks()
            .withFaceDescriptors();

          const resizedDetections = faceapi.resizeResults(
            detections,
            displaySize
          );

          const ctx = canvas.getContext('2d');
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          const results = resizedDetections.map((d) => {
            return faceMatcher.findBestMatch(d.descriptor);
          });
          results.forEach((result, i) => {
            const box = resizedDetections[i].detection.box;
            const drawBox = new faceapi.draw.DrawBox(box, {
              label: result,
            });
            drawBox.draw(canvas);
          });
        }, 100);
      }
    };

    loadModelsAndStart();

    const video = videoRef.current;
    if (video && isWebcamStarted) {
      video.addEventListener('play', onVideoPlay); // Call onVideoPlay when video starts playing
    }

    // Cleanup function
    return () => {
      if (video && isWebcamStarted) {
        video.removeEventListener('play', onVideoPlay); // Remove event listener when component unmounts
        if (video.srcObject) {
          const tracks = video.srcObject.getTracks();
          tracks.forEach((track) => track.stop()); // Stop all tracks of the stream
        }
      }
    };
  }, [isWebcamStarted, videoDimensions]);

  const handleStartWebcam = () => {
    startWebcam();
  };

  const startWebcam = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'user', // Set facingMode to 'user' to prevent mirroring
        },
        audio: false,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          setVideoDimensions({
            width: videoRef.current.videoWidth,
            height: videoRef.current.videoHeight,
          });
          setIsWebcamStarted(true);
        };
      }
    } catch (error) {
      if (
        error.name === 'NotAllowedError' ||
        error.name === 'PermissionDeniedError'
      ) {
        console.error('Permission to access webcam was denied.');
      } else if (
        error.name === 'NotFoundError' ||
        error.name === 'SourceUnavailableError'
      ) {
        console.error('No webcam detected.');
      } else {
        console.error('Error accessing webcam:', error);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loader-wrapper">
          <Loader />
        </div>
      )}
      <div className="k-mt-5 heading-border">
        <h6 className="k-pl-5 k-pt-2 k-b-0 k-mb-2">Face Recognition</h6>
      </div>
      <div className="container-div k-mt-5">
        {!isWebcamStarted && (
          <div className="center-button cam-btn">
            <Button
              className="start-button k-button-solid-primary"
              onClick={handleStartWebcam}
            >
              Start WebCam
            </Button>
          </div>
        )}
        <div className="video-container">
          <div className="k-d-flex k-justify-content-center k-m-5 video-stream-container">
            <video
              id="video"
              ref={videoRef}
              autoPlay
              playsInline
              muted
              className={'stream-video'}
            />
            <canvas
              id="canvas"
              ref={canvasRef}
              className="position-absolute canvas"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default FaceRecognition;
