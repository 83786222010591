import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'smart-react';
import './Dashboard.scss';
/**
 * Main Login Screen
 * @returns {React.Element} returns main login screen
 */
const Dashboard = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => {}}>
      <>
        <>
          <div>
            <div className="k-mt-5 heading-border">
              <h6 className="k-pl-5 k-pt-2 k-b-0 k-mb-2">
                Welcome To Smart AI
              </h6>
            </div>
            <iframe
              className="reportClass k-border-0"
              src="https://app.powerbi.com/view?r=eyJrIjoiZTQxZDQwZDItNmUzZS00NzMzLTgxNzMtOGVlNTM1MzdlODVmIiwidCI6Ijg5YjgwYjdkLTdlMzAtNDhmNS1hMjA5LTMwYjdmNmFhOWVkNSIsImMiOjN9"
            ></iframe>
          </div>
        </>
      </>
    </ErrorBoundary>
  );
};

export default Dashboard;
