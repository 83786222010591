import React, { useState, useRef } from 'react';
import { Upload } from '@progress/kendo-react-upload';
import { buildNotification, GenerateNotification, Loader } from 'smart-react';
import { uploadAndProcess, submitComment } from '../Services/imageService';
import {
  CREATE_MESSAGE,
  ERROR_MESSAGE,
  MODEL_TYPES_SELECT_LIST,
} from '../../../constants/applicationConstants';
import { Button } from '@progress/kendo-react-buttons';
import { TextArea } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import './MultiImageProcess.scss';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from '@progress/kendo-react-layout';
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';

/**
 * Component for uploading and processing multiple images.
 * @returns {JSX.Element} MultipleImageUpload component.
 */
const MultipleImageUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [comments, setComments] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [commentPopupVisible, setCommentPopupVisible] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [typeSelectionDialogVisible, setTypeSelectionDialogVisible] =
    useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // New state for selected image
  const uploadRef = useRef(null);
  const [typeOptions] = useState(MODEL_TYPES_SELECT_LIST);

  /**
   * Handler for saving files.
   * @param {Array} files - Array of files to be saved.
   * @returns {Promise} Promise representing the saving process.
   */
  const onSaveRequest = (files) => {
    const promises = files.map((file) => {
      const uid = file.uid;
      return new Promise((resolve) => {
        // Set progress to 100 directly
        progressRef.current[uid] = 100;
        resolve({ uid });
      });
    });

    return Promise.all(promises);
  };
  /**
   * Handler for canceling file upload.
   * @param {object} uid - Unique identifier of the file.
   */
  const onCancel = (uid) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.uid !== uid.uid)
    );
  };
  /**
   * Handler for removing files from selected files.
   * @param {Array} files - Array of files to be removed.
   */
  const onRemoveRequest = (files, _) => {
    const uid = files[0].uid;
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.uid !== uid)
    );
    return new Promise((resolve) => setTimeout(resolve, 300));
  };
  /**
   * Handler for uploading files.
   * @param {object} event - Upload event containing new state.
   */
  const handleUpload = (event) => {
    setSelectedFiles((prevFiles) => prevFiles.concat(event.newState));
  };
  /**
   * Handler for processing uploaded images.
   */
  const handleProcess = async () => {
    if (selectedFiles.length === 0) {
      GenerateNotification(
        buildNotification({
          title: ERROR_MESSAGE?.title,
          description: 'Please upload images before processing!',
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
      return;
    }

    if (!selectedType) {
      setTypeSelectionDialogVisible(true);
      return;
    }
    if (!selectedType.value) {
      GenerateNotification(
        buildNotification({
          title: ERROR_MESSAGE?.title,
          description: 'Please select a type!',
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
      setTypeSelectionDialogVisible(true);
      return;
    }
    setTypeSelectionDialogVisible(false);

    const formData = new FormData();
    selectedFiles.forEach((file) =>
      formData.append('files', file.getRawFile())
    );
    setIsLoader(true);
    try {
      const response = await uploadAndProcess(formData, selectedType.value);
      if (response.isSuccess) {
        setResultData(response.payload);
      }
      setSelectedFiles([]);
      resetUpload();
      GenerateNotification(
        buildNotification({
          title: 'Successfully Processed!',
          style: CREATE_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: ERROR_MESSAGE?.title,
          description: error,
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setSelectedType('');
      setIsLoader(false);
    }
  };
  /**
   * Handler for changing comment status.
   * @param {string} id - ID of the image.
   * @param {string} status - Status of the comment.
   */
  const handleCommentStatus = async (id, status) => {
    if (status === 'rejected') {
      setSelectedImageId(id);
      setCommentPopupVisible(true);
    } else {
      await updateCommentStatus(id, status);
    }
  };
  /**
   * Handler for submitting a comment.
   */
  const handleCommentSubmit = async () => {
    await updateCommentStatus(selectedImageId, 'rejected');
    setComments((prevComments) => ({ ...prevComments, [selectedImageId]: '' }));
    setCommentPopupVisible(false);
  };
  /**
   * Handler for updating comment status.
   * @param {string} id - ID of the image.
   * @param {string} status - Status of the comment.
   */
  const updateCommentStatus = async (id, status) => {
    try {
      setIsLoader(true);
      setCommentPopupVisible(false);
      await submitComment({ id, comment: comments[id], status });
      setResultData((prevResultData) =>
        prevResultData.filter((image) => image.id !== id)
      );
      GenerateNotification(
        buildNotification({
          title: 'Successfully Commented!',
          style: CREATE_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: ERROR_MESSAGE?.title,
          description: error,
          style: ERROR_MESSAGE?.style,
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setIsLoader(false);
    }
  };
  /**
   * Resets the upload component by clearing the selected files.
   */
  const resetUpload = () => {
    if (uploadRef.current) {
      uploadRef.current.setState({ files: [] }); // Clear the files directly in the Upload component's state
    }
  };
  /**
   * Handles the change event for the select component.
   * @param {object} event - Change event.
   */

  const handleSelectChange = (event) => {
    setSelectedType(event.value);
  };
  /**
   * Handles the click event for an image.
   * @param {object} image - Selected image.
   */
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="container-div">
      {isLoader && (
        <div className="loader-wrapper">
          <Loader />
        </div>
      )}
      <h6 className="k-p-4">Upload Images</h6>
      <div className="k-p-5">
        <Upload
          batch={false}
          multiple={true}
          defaultFiles={[]}
          onAdd={handleUpload}
          saveUrl={onSaveRequest}
          removeUrl={onRemoveRequest}
          onCancel={onCancel}
          withCredentials={false} // enable only for CORS
          ref={uploadRef}
        />
      </div>
      <div className="k-p-4 k-text-right">
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary k-mt-2"
          onClick={handleProcess}
        >
          Process
        </button>
      </div>
      {resultData.length > 0 && (
        <div className="k-p-5 k-m-5 multi-container k-border k-border-solid">
          {resultData.map((image) => (
            <div
              key={image.id}
              className="multi-image-card k-m-5 multi-container-item"
            >
              <Card>
                <CardHeader className="k-hbox">
                  <div>
                    <CardTitle>
                      <p className="k-m-0 k-p-0">Processed Image</p>
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardBody>
                  <img
                    src={image.result_file_path}
                    className="card-image"
                    alt="Result"
                    style={{
                      cursor: 'pointer',
                      maxWidth: '100%',
                      height: 'auto',
                      borderRadius: '5px',
                    }}
                    onClick={() => handleImageClick(image)}
                  />
                </CardBody>
                <div className="k-mb-3 k-text-center">
                  <Button
                    className="k-button-solid-primary k-m-3"
                    onClick={() => handleCommentStatus(image.id, 'approved')}
                  >
                    <span className="k-button-icon k-font-icon k-i-check"></span>{' '}
                    Approve
                  </Button>
                  <Button
                    className="k-button k-button-md k-rounded-md k-button-solid reject-button k-m-3"
                    onClick={() => handleCommentStatus(image.id, 'rejected')}
                  >
                    <span className="k-button-icon k-font-icon k-i-x"></span>{' '}
                    Reject
                  </Button>
                </div>
              </Card>
            </div>
          ))}
        </div>
      )}
      {commentPopupVisible && (
        <Dialog
          title="Reject Image with Comment"
          onClose={() => setCommentPopupVisible(false)}
          visible={commentPopupVisible}
        >
          <CardBody>
            <TextArea
              value={comments[selectedImageId] || ''}
              onChange={(e) =>
                setComments((prevComments) => ({
                  ...prevComments,
                  [selectedImageId]: e.target.value,
                }))
              }
              placeholder="Add comment"
            />
          </CardBody>
          <DialogActionsBar>
            <Button
              className="k-button-solid-primary k-mt-2"
              onClick={handleCommentSubmit}
            >
              Submit
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {selectedImage && ( // Render dialog only if selectedImage is not null
        <Dialog
          title="Image Details"
          onClose={() => setSelectedImage(null)}
          visible={true}
        >
          <CardBody>
            <img
              src={selectedImage.result_file_path}
              alt="Large Result"
              style={{ maxWidth: '100%' }}
            />
            <h6>Processing Details:</h6>
            {selectedImage?.tag_counts && (
              <ul>
                {Object.entries(selectedImage.tag_counts).map(
                  ([key, value]) => (
                    <li key={key}>
                      {key}: {value}
                    </li>
                  )
                )}
              </ul>
            )}
            {selectedImage?.comment && selectedImage.comment !== undefined && (
              <>
                <h6 className="k-text-left">Comment</h6>
                <p className="k-text-left k-ml-4">{selectedImage.comment}</p>
              </>
            )}
          </CardBody>
        </Dialog>
      )}
      {typeSelectionDialogVisible && (
        <Dialog
          title="Select Image Type"
          onClose={() => setTypeSelectionDialogVisible(false)}
          visible={typeSelectionDialogVisible}
        >
          <CardBody>
            <p>Select the type for processing:</p>
            <DropDownList
              data={typeOptions}
              value={selectedType}
              onChange={handleSelectChange}
              textField="text"
              valueField="value"
              defaultItem={{ text: 'Select Type...', value: null }}
              style={{ width: '300px' }}
            />
          </CardBody>
          <DialogActionsBar>
            <Button
              className="k-button-solid-primary k-mt-2"
              onClick={() => {
                handleProcess();
              }}
            >
              Submit
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default MultipleImageUpload;
